import { call, takeEvery, put } from 'redux-saga/effects';
import {
  COMMENT_SAVE_REQUEST,
  CommentSaveRequestAction,
  CommentFromResponse,
  COMMENT_SHOW_QUESTION,
  CommentShowSelectedQuestionAction,
  CommentShowSelectedCommentAction,
  COMMENT_SHOW_THREAD,
  COMMENT_SHOW_TEXT,
  CommentShowSelectedTextAction,
  COMMENT_DELETE_REQUEST,
  CommentDeleteRequestAction,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const saveComment = function* async({
  comment: commentData,
}: CommentSaveRequestAction): Generator {
  try {
    if (
      typeof commentData.refKey === 'object' &&
      typeof commentData.refKey !== 'string'
    ) {
      throw 'Please, select a section';
    }

    const comment = yield call(api.saveComment, commentData);

    yield put(actions.commentSaveSuccess(comment as CommentFromResponse));

    if (commentData.assessmentId) {
      yield put(
        actions.threadsLoadRequest({ assessmentId: commentData.assessmentId }),
      );
    }

    if (commentData.documentDraftId) {
      yield put(
        actions.threadsLoadRequest({
          documentDraftId: commentData.documentDraftId,
        }),
      );
    }
  } catch (err) {
    let label = 'Failed to save comment';

    if (err === 'Please, select a section') {
      label = err;
    }

    yield put(actions.commentSaveError(label, err as Error));
  }
};

export const scrollToId = function* ({
  id,
}:
  | CommentShowSelectedCommentAction
  | CommentShowSelectedQuestionAction
  | CommentShowSelectedTextAction): Generator {
  const target: HTMLTableRowElement | null = document.querySelector(
    `#${CSS.escape(id)}`,
  );
  target?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const deleteComment = function* async({
  commentId,
  assessmentId,
  documentDraftId,
}: CommentDeleteRequestAction): Generator {
  try {
    yield call(api.deleteComment, commentId);

    yield put(actions.commentDeleteSuccess(commentId));

    if (assessmentId) {
      yield put(actions.threadsLoadRequest({ assessmentId }));
    }
    if (documentDraftId) {
      yield put(actions.threadsLoadRequest({ documentDraftId }));
      yield put(actions.documentDraftLoadRequest(documentDraftId, true));
    }
  } catch (err) {
    yield put(
      actions.commentDeleteError('Failed to delete comment', err as Error),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(COMMENT_SAVE_REQUEST, saveComment),
    yield takeEvery(COMMENT_SHOW_QUESTION, scrollToId),
    yield takeEvery(COMMENT_SHOW_TEXT, scrollToId),
    yield takeEvery(COMMENT_SHOW_THREAD, scrollToId),
    yield takeEvery(COMMENT_DELETE_REQUEST, deleteComment),
  ];
}
