import React from 'react';
import { Dispatch } from 'redux';
import { Tooltip } from 'antd';
import type { History } from 'history';

import { confirmDialog } from 'src/shared/utils';
import { BasicUser, TrainingPeriodListingElement } from 'src/store/types';
import { RoutesEnum } from 'src/shared/enums';
import {
  clear2FARequest,
  impersonateRequest,
  resetPasswordRequest,
  userDeleteRequest,
} from 'src/store/actions/user';
import { useAuth } from 'src/hooks/useAuth';
import { Menu } from './UsersTableSettingsStyles';

export interface MenuProps {
  user: BasicUser;
  history: History;
  dispatch: Dispatch;
  onEdit: (userId: number) => void;
  onReleaseIndividualTraining?: (user: BasicUser) => void;
  openTrainingPeriod?: TrainingPeriodListingElement;
  isGlobalScreen?: boolean;
}

export const MenuList = (props: MenuProps) => {
  const {
    user,
    history,
    dispatch,
    onEdit,
    onReleaseIndividualTraining,
    openTrainingPeriod,
    isGlobalScreen,
  } = props;
  const { replace } = history;
  const { isAdmin, isCompanyModerator } = useAuth();

  const handleImpersonateUser = () => {
    replace(RoutesEnum.HOME);
    dispatch(impersonateRequest(user.email));
  };

  const canUserImpersonate = isAdmin || isCompanyModerator;

  return (
    <Menu>
      <Menu.Item key={1} onClick={() => onEdit(user.id)}>
        <span>Edit user</span>
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => dispatch(clear2FARequest(user.id))}
        disabled={!user.isTwoFactorAuthenticationEnabled}
      >
        <Tooltip
          title={
            !user.isTwoFactorAuthenticationEnabled
              ? "This user doesn't have 2FA"
              : ''
          }
        >
          <span>Clear 2FA</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        key={3}
        onClick={() => dispatch(resetPasswordRequest(user.email))}
      >
        <span>Reset Password</span>
      </Menu.Item>
      {canUserImpersonate && (
        <Menu.Item
          key={4}
          onClick={() =>
            confirmDialog({
              text: "You are about to impersonate to the user's session, are you sure about this?",
              onOk: handleImpersonateUser,
            })
          }
          disabled={!user.isTwoFactorAuthenticationEnabled}
        >
          <span>Impersonate user</span>
        </Menu.Item>
      )}
      {!isGlobalScreen && (
        <Menu.Item
          key={5}
          disabled={!!openTrainingPeriod}
          onClick={() =>
            onReleaseIndividualTraining
              ? onReleaseIndividualTraining(user)
              : console.error('onReleaseIndividualTraining is not defined')
          }
        >
          <span>Set individual training</span>
        </Menu.Item>
      )}
      <Menu.Item
        key={6}
        onClick={() =>
          confirmDialog({
            text: 'You are about to delete a user, are you sure about this?',
            onOk: () => {
              dispatch(userDeleteRequest(user.id));
            },
          })
        }
      >
        <span>Delete user</span>
      </Menu.Item>
    </Menu>
  );
};
