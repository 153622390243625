import styled, { css } from 'styled-components';
import { Row } from 'antd';
import { remConvert } from 'src/theme/utils';
import { centeredFlex } from 'src/theme';

const baseNormalFont = css`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const RisksCollapsibleContainer = styled.div<{ disabled?: boolean }>`
  margin-bottom: ${remConvert(24)};
  padding: ${remConvert(0)} ${remConvert(24)} ${remConvert(0)} ${remConvert(24)};
  background-color: ${({ theme }) => theme.colors.alabaster.main};
  border: 1px solid ${({ theme }) => theme.colors.grayscale.moonGray};
  border-radius: ${remConvert(4)};

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.6;
  `}
`;

export const RisksCollapsibleHeader = styled.div`
  padding: ${remConvert(24)};
`;

export const RisksCollapsibleHeaderFirstRow = styled(Row)``;

export const RisksCollapsibleHeaderSecondRow = styled(Row)``;

export const RisksCollapsibleTitle = styled.div`
  ${baseNormalFont}
  font-size: 16px;
  line-height: ${remConvert(41)};
  letter-spacing: 0.15em;
  color: rgba(0, 0, 0, 0.6);
`;

export const RiskCollapsibleContent = styled.div`
  margin: 0 ${remConvert(24)} 0 ${remConvert(24)};
  border-top: ${remConvert(1)} solid
    ${({ theme }) => theme.colors.alto.secondary};
`;

export const RiskSectionContentContainer = styled.span`
  ${baseNormalFont}
  ${centeredFlex}
  font-size: 30px;
  line-height: ${remConvert(41)};
  letter-spacing: 0.15em;
  color: rgba(0, 0, 0, 0.6);
  justify-content: center;
`;

export const RiskSectionsContent = styled.span`
  ${baseNormalFont}
  margin-right: ${remConvert(18)};
  line-height: ${remConvert(27)};
  font-size: 14px;
  text-align: right;
  vertical-align: center;
  letter-spacing: 0.15em;
  color: rgba(0, 0, 0, 0.6);
`;

export const RiskSectionsCompliantStatus = styled.span`
  ${baseNormalFont}
  ${centeredFlex}
  font-weight: 700;
  font-size: 16px;
  line-height: ${remConvert(22)};
  color: rgba(0, 0, 0, 0.6);
`;
