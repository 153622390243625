/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { getThreadListingLoader } from 'src/store/selectors';
import { Button } from 'src/components/Button';
import {
  CommentFromResponse,
  RootState,
  FileData,
  User,
} from 'src/store/types';
import {
  EyeInvisibleOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from 'src/theme/icons';
import s3 from 'src/services/downloadFile';
import { SimpleUserAvatar } from 'src/features/user/components';

interface DialogCommentProps {
  comment: CommentFromResponse;
  handleReply: () => void;
  handleDelete: () => void;
  taggedUsers?: User[];
}

const MainContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.moonGray};

  display: flex;
  margin: 10px 0;
  padding: 10px 0px 5px 5px;
  flex-direction: column;
  position: relative;

  &.reply {
    padding-left: 20px;
  }

  &.admin-only {
    background-color: #f3f3f3;

    .admin-only-warn {
      color: #a0a0a0;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 5px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.avatar {
    margin: 4px 14px 4px 0;
  }

  div.delete-icon {
    margin-left: auto;
    margin-right: 14px;

    span {
      font-size: 14px;
    }
  }

  h4 {
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.grayscale.darkGray};
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.grayscale.preDark};
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
  }
`;

const BodyContainer = styled.div`
  margin: 8px 0;
  font-size: 12px;
`;

const TaggedUsersContainer = styled.div`
  margin: 10px 0;

  div {
    display: flex;
    flex-direction: row;
    .avatar {
      padding: 1px; 
    }
    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.deepBlue.main};
    }
  }
  div 
`;

const AttachmentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 2px;
`;

const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.darkBlue.main};
  cursor: pointer;
  font-size: 12px;

  .anticon {
    font-size: 16px;
  }

  span:not(.anticon) {
    margin-left: 2px;
  }
`;

const FooterContainer = styled.div`
  padding: 8px 0px;
`;

const ReplyRef = styled.div`
  font-size: 10px;
`;

export const DialogComment: FC<DialogCommentProps> = ({
  comment,
  handleReply,
  handleDelete,
  taggedUsers,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCommentLoading, setDeleteCommentLoading] = useState(false);

  const { user } = useAuth();

  // The back-end always respond with the Author as User for /thread route
  const author: User = comment.author!;

  const authorParent = useSelector((state: RootState) => {
    if (!comment.parent || !comment.parent.author) {
      return undefined;
    }
    return comment.parent?.author?.name;
  });

  const threadListingLoader = useSelector(getThreadListingLoader);

  const isOwnComment = comment.author?.id === user.id;
  const canDeleteComment =
    comment.author?.id === user.id || user.role === 'admin';
  const isReply = !!comment.parent;
  const parent = isReply && authorParent ? `In reply to ${authorParent}` : null;

  const classes = isReply ? ['reply'] : [];

  if (comment.adminOnly) {
    classes.push('admin-only');
  }

  const deleteComment = () => {
    setDeleteCommentLoading(true);
    handleDelete();
  };

  // Fix here for TH2-131
  useEffect(() => {
    if (threadListingLoader.completed && threadListingLoader.success) {
      setDeleteCommentLoading(false);
    }
  }, [threadListingLoader]);

  return (
    <MainContainer className={classes.join(' ')}>
      <Modal
        title="Delete comment"
        open={showDeleteModal}
        onOk={deleteComment}
        confirmLoading={deleteCommentLoading}
        onCancel={() => setShowDeleteModal(false)}
      >
        <p>Are you sure you want to delete your comment?</p>
      </Modal>
      {isReply && <ReplyRef>{parent}</ReplyRef>}
      {comment.adminOnly && (
        <p className="admin-only-warn">
          <EyeInvisibleOutlined /> {` `}
          Admin only
        </p>
      )}
      <HeaderContainer>
        <div className="avatar">{SimpleUserAvatar({ user: author })}</div>
        <div>
          <h4>
            {author?.name} {isOwnComment ? `(You)` : ``}
          </h4>
          <span>{moment(comment.createdAt).format('lll')}</span>
        </div>
        {canDeleteComment && (
          <div className="delete-icon">
            <DeleteOutlined onClick={() => setShowDeleteModal(true)} />
          </div>
        )}
      </HeaderContainer>
      <BodyContainer>{comment.comment ? comment.comment : ''}</BodyContainer>
      {comment.files && (
        <AttachmentListContainer>
          {(JSON.parse(comment.files) as FileData[]).map((f) => (
            // TODO: Verify the type of xhr
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            <AttachmentContainer
              key={f.name}
              onClick={() => s3.handleFileDownload(f.xhr?.key!, f.name)}
            >
              <PaperClipOutlined />
              <span>{f.name}</span>
            </AttachmentContainer>
          ))}
        </AttachmentListContainer>
      )}
      <FooterContainer>
        {taggedUsers && taggedUsers.length > 0 && (
          <TaggedUsersContainer>
            <h4>Tagged participants</h4>
            <div className="taggedBox">
              {taggedUsers.map((taggedUser: User) => {
                return (
                  <div className="avatar" key={`users${taggedUser.id}`}>
                    {SimpleUserAvatar({ user: taggedUser, size: 20 })}
                  </div>
                );
              })}
            </div>
          </TaggedUsersContainer>
        )}
        <Button
          variant="tertiary"
          altered
          title="Reply"
          onClick={handleReply}
        />
      </FooterContainer>
    </MainContainer>
  );
};
