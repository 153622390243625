import { User, UserListingIndexedItem } from 'src/store/types';
import { mapUserRoleText } from 'src/shared/utils/user';
import { UserTableRow } from './UserListingTable';

export const dataMapper = (users: UserListingIndexedItem): UserTableRow[] => {
  return Object.values(users).map(
    ({
      id,
      name,
      email,
      role,
      isTwoFactorAuthenticationEnabled,
      company,
    }: User) => ({
      id,
      name,
      email,
      role,
      roleName: mapUserRoleText(role),
      companyName: company ? company.name : '',
      companyId: company ? company.id : 0,
      isTwoFactorAuthenticationEnabled,
    }),
  );
};
