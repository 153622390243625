import {
  CompaniesLoadRequestAction,
  COMPANIES_LOAD_REQUEST,
  CompaniesLoadSuccessAction,
  COMPANIES_LOAD_SUCCESS,
  CompaniesLoadErrorAction,
  COMPANIES_LOAD_ERROR,
  COMPANY_ARCHIVE_REQUEST,
  ArchiveCompanyRequestAction,
  COMPANY_ARCHIVE_SUCCESS,
  ArchiveCompanySuccessAction,
  COMPANY_ARCHIVE_ERROR,
  ArchiveCompanyErrorAction,
  ADD_COMPANY_TO_LIST,
  AddCompanyToListAction,
  CompanyFromResponse,
  TypeOfTraining,
  PaginatedCompaniesResponse,
} from '../types';
import { showErrorMessage } from './error';

export const companiesLoadRequest = (
  typeOfTrainings: null | TypeOfTraining[] = null,
  active = true,
  page = 1,
  pageSize = 20,
  name = '',
  sortingField = 'name',
  sortOrder = 'ASC',
): CompaniesLoadRequestAction => ({
  type: COMPANIES_LOAD_REQUEST,
  request: {
    active,
    typeOfTrainings,
    page,
    pageSize,
    name,
    sortingField,
    sortOrder,
  },
});

export const companiesLoadSuccess = (
  paginatedCompanies: PaginatedCompaniesResponse,
): CompaniesLoadSuccessAction => ({
  type: COMPANIES_LOAD_SUCCESS,
  companies: paginatedCompanies.records,
  page: paginatedCompanies.page,
  pageSize: paginatedCompanies.pageSize,
  totalItems: paginatedCompanies.total,
});

export const companiesLoadError = (
  errorMessage: string,
  originalError?: Error,
): CompaniesLoadErrorAction =>
  showErrorMessage(COMPANIES_LOAD_ERROR, errorMessage, originalError);

export const archiveCompanyRequest = (
  companyId: number,
): ArchiveCompanyRequestAction => ({
  type: COMPANY_ARCHIVE_REQUEST,
  companyId,
});

export const archiveCompanySuccess = (
  archivedCompany: CompanyFromResponse,
): ArchiveCompanySuccessAction => ({
  type: COMPANY_ARCHIVE_SUCCESS,
  archivedCompany,
});

export const archiveCompanyError = (
  errorMessage: string,
  originalError?: Error,
): ArchiveCompanyErrorAction =>
  showErrorMessage(COMPANY_ARCHIVE_ERROR, errorMessage, originalError);

export const addCompanyToList = (
  company: CompanyFromResponse,
): AddCompanyToListAction => ({
  type: ADD_COMPANY_TO_LIST,
  company,
});
