import {
  Loader,
  CompanyFromResponse,
  CompanyState,
  NotificationListingLoadSuccessAction,
  NotificationsReadSuccessAction,
  ShowErrorAction,
  TypeOfTraining,
  TrainingType,
} from 'src/store/types';
import { AuthMethod, BodySMS } from 'src/types/common';
import { InterviewFromResponse } from './interview';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const IMPERSONATE_REQUEST = 'IMPERSONATE_REQUEST';
export const IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS';
export const IMPERSONATE_ERROR = 'IMPERSONATE_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const LOGIN_CHECK = 'LOGIN_CHECK';
export const LOGIN_CHECK_COMPLETED = 'LOGIN_CHECK_COMPLETED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_ERROR = 'USER_SAVE_ERROR';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

export const ADD_USER_TO_LIST = 'ADD_USER_TO_LIST';
export const GET_2FA_CODE_REQUEST = 'GET_2FA_CODE_REQUEST';
export const GET_2FA_CODE_SUCCESS = 'GET_2FA_CODE_SUCCESS';
export const GET_2FA_CODE_ERROR = 'GET_2FA_CODE_ERROR';
export const TURN_ON_2FA_REQUEST = 'TURN_ON_2FA_REQUEST';
export const TURN_ON_2FA_SUCCESS = 'TURN_ON_2FA_SUCCESS';
export const TURN_ON_2FA_ERROR = 'TURN_ON_2FA_ERROR';
export const AUTH_2FA_REQUEST = 'AUTH_2FA_REQUEST';
export const AUTH_2FA_SUCCESS = 'AUTH_2FA_SUCCESS';
export const AUTH_2FA_ERROR = 'AUTH_2FA_ERROR';

export const CLEAR_2FA_REQUEST = 'CLEAR_2FA_REQUEST';
export const CLEAR_2FA_SUCCESS = 'CLEAR_2FA_SUCCESS';
export const CLEAR_2FA_ERROR = 'CLEAR_2FA_ERROR';

export const MULTIPLE_USERS_SAVE_REQUEST = 'MULTIPLE_USERS_SAVE_REQUEST';
export const MULTIPLE_USERS_SAVE_SUCCESS = 'MULTIPLE_USERS_SAVE_SUCCESS';
export const MULTIPLE_USERS_SAVE_ERROR = 'MULTIPLE_USERS_SAVE_ERROR';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST =
  'USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST';
export const USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS =
  'USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS';
export const USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_ERROR =
  'USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_ERROR';

export const GET_USER_ONBOARDING_REQUEST = 'GET_USER_ONBOARDING_REQUEST';
export const GET_USER_ONBOARDING_SUCCESS = 'GET_USER_ONBOARDING_SUCCESS';
export const GET_USER_ONBOARDING_ERROR = 'GET_USER_ONBOARDING_ERROR';

export const USER_COMPLETE_ONBOARDING_REQUEST =
  'USER_COMPLETE_ONBOARDING_REQUEST';
export const USER_COMPLETE_ONBOARDING_SUCCESS =
  'USER_COMPLETE_ONBOARDING_SUCCESS';
export const USER_COMPLETE_ONBOARDING_ERROR = 'USER_COMPLETE_ONBOARDING_ERROR';

export interface UserFromResponse {
  id: number;
  name: string;
  email: string;
  password?: string;
  company?: CompanyFromResponse;
  role: UserRole;
  legacyId?: number;
  isActive?: boolean;
  avatarImg?: string;
  interviews?: InterviewFromResponse[];
  isTwoFactorAuthenticationEnabled?: boolean;
  lastNotifiedAt?: string;
  trainingType?: TrainingType;
  allowSkipTraining?: boolean;
  allowSkipToRiskAssessment?: boolean;
  preference: Preference;
  isOnboardingRequired: boolean;
}

export interface PaginatedUsers {
  records: UserFromResponse[];
  page: number;
  pageSize: number;
  total: number;
}

export type UserOnboardingFromResponse = Omit<
  UserFromResponse,
  | 'name'
  | 'email'
  | 'password'
  | 'company'
  | 'legacyId'
  | 'isActive'
  | 'avatarImg'
  | 'interviews'
  | 'isTwoFactorAuthenticationEnabled'
  | 'lastNotifiedAt'
  | 'trainingType'
  | 'allowSkipTraining'
  | 'allowSkipToRiskAssessment'
  | 'preference'
>;

export interface BasicUser {
  id: number;
  email: string;
  name: string;
  role: UserRole;
  isTwoFactorAuthenticationEnabled: boolean;
}

export interface Trainee extends BasicUser {
  trainingType: string | null;
  progress: number | null;
  score: number | null;
  lastLoginAt: Date | null;
}

export interface UserListingFilters {
  offset: number;
  limit: number;
}

export interface User extends Partial<BodySMS> {
  id: number;
  name: string;
  email: string;
  password?: string;
  role: UserRole;
  company?: CompanyState;
  legacyId?: number;
  isActive?: boolean;
  avatarImg?: string;
  interviewIds?: number[];
  hasPendingNotifications: boolean;
  authMethod: AuthMethod;
  isTwoFactorAuthenticationEnabled: boolean;
  isSecondFactorAuthenticated: boolean;
  twoFactorQR?: string;
  trainingTypeId?: number;
  allowSkipTraining?: boolean;
  allowSkipToRiskAssessment?: boolean;
  preference: Preference;
  attributes: Attributes;
  isOnboardingRequired: boolean;
}

export interface Preference {
  collapseSideBar: boolean;
}

export interface Attributes {
  typeOfTrainings?: TypeOfTraining[];
}

export interface UserUpdateInput {
  id?: number;
  name?: string;
  email?: string;
  password?: string;
  isActive?: boolean;
  avatarImg?: string;
  role?: CompanyUserRole;
  isTwoFactorAuthenticationEnabled?: boolean;
  trainingTypeId?: number;
  allowSkipTraining?: boolean;
  allowSkipToRiskAssessment?: boolean;
  typeOfTrainings?: TypeOfTraining[];
}

export interface UserInput {
  name: string;
  email: string;
  role: CompanyUserRole;
  companyId?: number;
  trainingTypeId?: number;
  allowSkipTraining?: boolean;
  allowSkipToRiskAssessment?: boolean;
  isActive: boolean;
  typeOfTrainings?: TypeOfTraining[];
}

export interface UserListingIndexedItem {
  [key: string]: User;
}

export interface UserListingState {
  users: UserListingIndexedItem;
  totalItems: number;
  loader: Loader;
}

export interface SelectedUserState {
  user: User;
  loader: Loader;
}

export enum UserRole {
  admin = 'admin',
  company_moderator = 'company_moderator',
  officer = 'officer',
  auditor = 'auditor',
  user = 'user',
  trainee = 'trainee',
  anonymous = 'anonymous',
}

export enum CompanyUserRole {
  admin = 'admin',
  company_moderator = 'company_moderator',
  officer = 'officer',
  auditor = 'auditor',
  trainee = 'trainee',
}

export interface UserUpdateCollapsedSiderPreferenceRequestAction {
  type: typeof USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST;
  collapsedSider: boolean;
}

export interface UserUpdateCollapsedSiderPreferenceSuccessAction {
  type: typeof USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS;
  collapsedSider: boolean;
}

export type UserUpdateCollapsedSiderPreferenceErrorAction = ShowErrorAction<
  typeof USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_ERROR
>;

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
  email: string;
  password: string;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  user: User;
}

export type LoginErrorAction = ShowErrorAction<typeof LOGIN_ERROR>;

export interface ImpersonateRequestAction {
  type: typeof IMPERSONATE_REQUEST;
  email: string;
}

export interface ImpersonateSuccessAction {
  type: typeof IMPERSONATE_SUCCESS;
  user: User;
}

export type ImpersonateErrorAction = ShowErrorAction<typeof IMPERSONATE_ERROR>;

export interface LogoutRequestAction {
  type: typeof LOGOUT_REQUEST;
}

export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export type LogoutErrorAction = ShowErrorAction<typeof LOGOUT_ERROR>;

export interface ResetPasswordRequestAction {
  type: typeof RESET_PASSWORD_REQUEST;
  username: string;
}

export interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export type ResetPasswordErrorAction = ShowErrorAction<
  typeof RESET_PASSWORD_ERROR
>;

export interface LoginCheckAction {
  type: typeof LOGIN_CHECK | typeof LOGIN_CHECK_COMPLETED;
  user?: User;
}

export interface UserUpdateRequestAction {
  type: typeof USER_UPDATE_REQUEST;
  userInput: UserUpdateInput;
  authToken?: string;
  updateUserData?: boolean;
}

export interface UserUpdateSuccessAction {
  type: typeof USER_UPDATE_SUCCESS;
  user: User;
}

export type UserUpdateErrorAction = ShowErrorAction<typeof USER_UPDATE_ERROR>;

export interface UserSaveRequestAction {
  type: typeof USER_SAVE_REQUEST;
  user: UserInput;
}

export interface UserSaveSuccessAction {
  type: typeof USER_SAVE_SUCCESS;
  user: UserFromResponse;
}

export type UserSaveErrorAction = ShowErrorAction<typeof USER_SAVE_ERROR>;

export interface UserDeleteRequestAction {
  type: typeof USER_DELETE_REQUEST;
  id: number;
}

export interface UserDeleteSuccessAction {
  type: typeof USER_DELETE_SUCCESS;
  id: number;
}

export type UserDeleteErrorAction = ShowErrorAction<typeof USER_DELETE_ERROR>;

export interface AddUserToListAction {
  type: typeof ADD_USER_TO_LIST;
  user: UserFromResponse;
}

export interface Get2FACodeRequestAction {
  type: typeof GET_2FA_CODE_REQUEST;
}

export interface Get2FACodeSuccessAction {
  type: typeof GET_2FA_CODE_SUCCESS;
  code: string;
}

export type Get2FACodeErrorAction = ShowErrorAction<typeof GET_2FA_CODE_ERROR>;

export interface TwoFaRequestBody {
  code: string;
  authMethod: AuthMethod;
}

export interface TurnOn2FARequestAction extends TwoFaRequestBody {
  type: typeof TURN_ON_2FA_REQUEST;
  dialCode?: string;
  phoneNumber?: string;
}

export interface TurnOn2FASuccessAction {
  type: typeof TURN_ON_2FA_SUCCESS;
  isTwoFactorAuthenticationEnabled: boolean;
  user: User;
}
export type TurnOn2FAErrorAction = ShowErrorAction<typeof TURN_ON_2FA_ERROR>;

export interface Clear2FARequestAction {
  type: typeof CLEAR_2FA_REQUEST;
  userId: number;
}

export interface Clear2FASuccessAction {
  type: typeof CLEAR_2FA_SUCCESS;
  userId: number;
}

export type Clear2FAErrorAction = ShowErrorAction<typeof CLEAR_2FA_ERROR>;

export interface Auth2FARequestAction extends TwoFaRequestBody {
  type: typeof AUTH_2FA_REQUEST;
}

export type Auth2FAErrorAction = ShowErrorAction<typeof AUTH_2FA_ERROR>;

export interface Auth2FASuccessAction {
  type: typeof AUTH_2FA_SUCCESS;
  user: User;
}

export interface MultipleUsersSaveRequestAction {
  type: typeof MULTIPLE_USERS_SAVE_REQUEST;
  users: UserInput[];
}

export interface MultipleUsersSaveSuccessAction {
  type: typeof MULTIPLE_USERS_SAVE_SUCCESS;
  users: UserFromResponse[];
}

export type MultipleUsersSaveErrorAction = ShowErrorAction<
  typeof MULTIPLE_USERS_SAVE_ERROR
>;

export interface UserCreationError {
  error: string;
  recordNumber: number;
}

export interface MultipleUsersCreationResponse {
  data: UserFromResponse[];
  errors: UserCreationError[];
}

export interface GetUsersRequestAction {
  type: typeof GET_USERS_REQUEST;
  page: number;
  pageSize: number;
  filter: string;
}

export interface GetUsersSuccessAction {
  type: typeof GET_USERS_SUCCESS;
  users: PaginatedUsers;
}

export type GetUsersErrorAction = ShowErrorAction<typeof GET_USERS_ERROR>;

export interface GetUserOnboardingRequestAction {
  type: typeof GET_USER_ONBOARDING_REQUEST;
  id: number;
}

export interface GetUserOnboardingSuccessAction {
  type: typeof GET_USER_ONBOARDING_SUCCESS;
  user: UserOnboardingFromResponse;
}

export type GetUserOnboardingErrorAction = {
  type: typeof GET_USER_ONBOARDING_ERROR;
  message: string;
};

export interface UserCompleteOnboardingRequestAction {
  type: typeof USER_COMPLETE_ONBOARDING_REQUEST;
  id: number;
}

export interface UserCompleteOnboardingSuccessAction {
  type: typeof USER_COMPLETE_ONBOARDING_SUCCESS;
  user: UserFromResponse;
}

export type UserCompleteOnboardingErrorAction = ShowErrorAction<
  typeof USER_COMPLETE_ONBOARDING_ERROR
>;

export type UserActionTypes =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginErrorAction
  | ImpersonateRequestAction
  | ImpersonateSuccessAction
  | ImpersonateErrorAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | LoginCheckAction
  | NotificationListingLoadSuccessAction
  | NotificationsReadSuccessAction
  | ResetPasswordErrorAction
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | UserUpdateRequestAction
  | UserUpdateSuccessAction
  | UserUpdateErrorAction
  | AddUserToListAction
  | UserSaveRequestAction
  | UserSaveSuccessAction
  | UserSaveErrorAction
  | Get2FACodeRequestAction
  | Get2FACodeSuccessAction
  | Get2FACodeErrorAction
  | TurnOn2FARequestAction
  | TurnOn2FASuccessAction
  | TurnOn2FAErrorAction
  | Auth2FAErrorAction
  | Auth2FASuccessAction
  | Auth2FARequestAction
  | MultipleUsersSaveRequestAction
  | MultipleUsersSaveSuccessAction
  | MultipleUsersSaveErrorAction
  | Clear2FAErrorAction
  | Clear2FARequestAction
  | Clear2FASuccessAction
  | UserDeleteRequestAction
  | UserDeleteSuccessAction
  | UserDeleteErrorAction
  | GetUsersRequestAction
  | GetUsersSuccessAction
  | GetUsersErrorAction
  | UserUpdateCollapsedSiderPreferenceRequestAction
  | UserUpdateCollapsedSiderPreferenceSuccessAction
  | UserUpdateCollapsedSiderPreferenceErrorAction
  | GetUserOnboardingRequestAction
  | GetUserOnboardingSuccessAction
  | GetUserOnboardingErrorAction
  | UserCompleteOnboardingRequestAction
  | UserCompleteOnboardingSuccessAction
  | UserCompleteOnboardingErrorAction;
