import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';

import { THIconComplete } from 'src/theme/media';
import { Button } from 'src/components/Button';
import { getUserLoader } from 'src/store/selectors/user';
import { userUpdateRequest } from 'src/store/actions/user';
import {
  minLength,
  maxLength,
  includesLowercase,
  includesSymbol,
  includesUppercase,
  includesNumber,
  validate,
  success,
  error,
} from 'src/shared/utils';

import { useAuth } from 'src/hooks/useAuth';

import * as S from './Styles';

interface PasswordChangeFormProps {
  authToken: string;
  userId: string;
}

export const PasswordChangeForm: FC<PasswordChangeFormProps> = ({
  authToken,
  userId,
}) => {
  const [password, setPassword] = useState<string | undefined>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<
    string | undefined
  >('');
  const [rulesError, setRulesError] = useState<string | undefined>(undefined);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [upperCaseLetters, setUpperCaseLetters] = useState<boolean>(false);
  const [numbers, setNumbers] = useState<boolean>(false);
  const [tenFiftyCharacters, setTenFiftyCharacters] = useState<boolean>(false);
  const [lowerCaseCharacter, setLowerCaseCharacter] = useState<boolean>(false);
  const [symbols, setSymbols] = useState<boolean>(false);
  const [passwordResetSubmitted, setPasswordResetSubmitted] =
    useState<boolean>(false);
  const userLoader = useSelector(getUserLoader);
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasPendingOnboarding, user } = useAuth();

  useEffect(() => {
    if (userLoader?.completed) {
      setLoading(false);

      if (userLoader?.error) {
        return void error(userLoader.error);
      }

      if (userLoader?.success && passwordResetSubmitted) {
        history.push(`/login${hasPendingOnboarding ? `?id=${user.id}` : ''}`);
        return void success('Successfully changed your password.');
      }
    }
  }, [userLoader]);

  useEffect(() => {
    if (password && passwordConfirmation) {
      setPasswordMatch(password === passwordConfirmation);
    } else {
      setPasswordMatch(false);
    }
  }, [password, passwordConfirmation]);

  const clearInputs = (): void => {
    setPassword('');
    setPasswordConfirmation('');
  };

  const handleSetPassword = (pwd: string): void => {
    setRulesError(undefined);

    validate(
      pwd,
      'Needs to have 10 or more characters',
      minLength(9),
      setRulesError,
    );
    validate(
      pwd,
      'Needs to have 50 or less characters',
      maxLength(51),
      setRulesError,
    );
    validate(
      pwd,
      'Needs to have 1 uppercase',
      includesUppercase,
      setRulesError,
    );
    validate(
      pwd,
      'Needs to have 1 lowercase',
      includesLowercase,
      setRulesError,
    );
    validate(pwd, 'Needs to have 1 number', includesNumber, setRulesError);
    validate(pwd, 'Needs to have 1 symbol', includesSymbol, setRulesError);

    if (minLength(9)(pwd) && maxLength(51)(pwd)) {
      setTenFiftyCharacters(true);
    } else {
      setTenFiftyCharacters(false);
    }

    if (includesUppercase(pwd)) {
      setUpperCaseLetters(true);
    } else {
      setUpperCaseLetters(false);
    }

    if (includesLowercase(pwd)) {
      setLowerCaseCharacter(true);
    } else {
      setLowerCaseCharacter(false);
    }

    if (includesNumber(pwd)) {
      setNumbers(true);
    } else {
      setNumbers(false);
    }

    if (includesSymbol(pwd)) {
      setSymbols(true);
    } else {
      setSymbols(false);
    }

    setPassword(pwd);
  };

  const handleSubmit = (): void => {
    if (validatePassword()) {
      setLoading(true);
      setPasswordResetSubmitted(true);
      dispatch(userUpdateRequest({ password, id: Number(userId) }, authToken));
      clearInputs();
    }
  };

  const validatePassword = (): boolean => {
    return password === passwordConfirmation;
  };

  return (
    <>
      <div>
        <S.PasswordTextInput
          autoFocus
          password
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetPassword(e.target.value);
          }}
          placeholder="Password"
          value={password}
          autoComplete="off"
          float
        />
        <S.PasswordTextInput
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPasswordConfirmation(e.target.value);
          }}
          password
          placeholder="Confirm password"
          value={passwordConfirmation}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit();
            }
          }}
          float
          autoComplete="off"
        />
        <S.PasswordRulesDescription>
          Your password can be between 10 - 50 characters and should contain
          uppercase and lowercase letters, numbers and symbols.
        </S.PasswordRulesDescription>
        <S.PasswordRulesContainer>
          <Row>
            <Col xs={8}>
              <S.PasswordRuleContainer isFulfilled={upperCaseLetters}>
                <S.PasswordRuleCheckIcon
                  src={THIconComplete}
                  width={20}
                  height={20}
                />
                Uppercase letters
              </S.PasswordRuleContainer>
            </Col>
            <Col xs={8}>
              <S.PasswordRuleContainer isFulfilled={numbers} isMiddle={true}>
                <S.PasswordRuleCheckIcon
                  src={THIconComplete}
                  width={20}
                  height={20}
                />
                Numbers
              </S.PasswordRuleContainer>
            </Col>
            <Col xs={8}>
              <S.PasswordRuleContainer isFulfilled={tenFiftyCharacters}>
                <S.PasswordRuleCheckIcon
                  src={THIconComplete}
                  width={20}
                  height={20}
                />
                10 - 50 characters
              </S.PasswordRuleContainer>
            </Col>
            <Col xs={8}>
              <S.PasswordRuleContainer isFulfilled={lowerCaseCharacter}>
                <S.PasswordRuleCheckIcon
                  src={THIconComplete}
                  width={20}
                  height={20}
                />
                Lowercase letters
              </S.PasswordRuleContainer>
            </Col>
            <Col xs={8}>
              <S.PasswordRuleContainer isFulfilled={symbols} isMiddle={true}>
                <S.PasswordRuleCheckIcon
                  src={THIconComplete}
                  width={20}
                  height={20}
                />
                Symbols
              </S.PasswordRuleContainer>
            </Col>
            <Col xs={8}>
              <S.PasswordRuleContainer isFulfilled={passwordMatch}>
                <S.PasswordRuleCheckIcon
                  src={THIconComplete}
                  width={20}
                  height={20}
                />
                Password match
              </S.PasswordRuleContainer>
            </Col>
          </Row>
        </S.PasswordRulesContainer>
      </div>
      <S.FormSubmitContainer>
        <Button
          className="btn"
          title="Create password"
          onClick={handleSubmit}
          loading={loading}
          disabled={!!rulesError || !passwordMatch}
        />
      </S.FormSubmitContainer>
    </>
  );
};
