import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { TopNavbar } from 'src/components/TopNavbar';
import { CompanyListingTable } from 'src/features/risk-assessment/components/CompanyListingTable';
import { SearchInput } from 'src/components/SearchInput';
import {
  CompanyState,
  RootState,
  TrainingTypesResponse,
} from 'src/store/types';
import {
  getAssessmentTypes,
  getCompanyLoaderFromState,
  getUserLoader,
  getAssessmentListingLoader,
  getIndexAssessmentsByCompany,
  getTrainingTypes,
  getCompanyListingFromState,
  getCompaniesLoader,
} from 'src/store/selectors';
import { Button } from 'src/components/Button';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormSetupAssessment } from 'src/features/risk-assessment/components/FormSetupAssessment';
import { FormCreateCompany } from 'src/features/risk-assessment/components/FormCreateCompany';
import { FormCreateUser } from 'src/features/user/components';
import actions from 'src/store/actions';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { COMPANY_GLOBAL_ROLES } from 'src/constants/user';
import { Pagination } from 'src/components/Pagination';
import { SortOrder } from 'antd/lib/table/interface';

enum Entity {
  COMPANY = 'COMPANY',
  ASSESSMENT = 'ASSESSMENT',
  USER = 'USER',
}

const CompanyListContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
  position: relative;
`;

const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px 0;
`;

export const CompanyListingPage: FC<unknown> = () => {
  const { typeOfTrainings } = useAuth();
  const [textToFilter, setTextToFilter] = useState<string>('');
  const [formPanelTitle, setFormPanelTitle] = useState<string>('');
  const [formPanelVisible, setFormPanelVisible] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedEntity, setSelectedEntity] = useState<Entity>(Entity.COMPANY);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortingField, setSortingField] = useState('name');
  const [sortOrder, setSortOrder] = useState<SortOrder | null>('ascend');

  const companies = useSelector((state: RootState) =>
    getIndexAssessmentsByCompany(state),
  );
  const paginatedCompaniesData = useSelector((state: RootState) =>
    getCompanyListingFromState(state),
  );

  const assessmentTypes = useSelector(getAssessmentTypes);
  const assessmentLoader = useSelector(getAssessmentListingLoader);
  const companyLoader = useSelector(getCompanyLoaderFromState);
  const companiesLoader = useSelector(getCompaniesLoader);
  const userLoader = useSelector(getUserLoader);
  const trainingTypes: TrainingTypesResponse = useSelector(getTrainingTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.trainingTypesLoadRequest());
    dispatch(actions.assessmentTypesLoadAllRequest());
    dispatch(actions.companiesLoadRequest(typeOfTrainings));
  }, []);

  useEffect(() => {
    if (assessmentLoader.completed && assessmentLoader.success) {
      dispatch(actions.assessmentTypesLoadAllRequest());
      dispatch(actions.companiesLoadRequest(typeOfTrainings));
      setFormPanelVisible(false);
      setSaveLoading(false);
      assessmentForm.resetFields();
    }
  }, [assessmentLoader]);

  useEffect(() => {
    if (companyLoader.completed && companyLoader.success) {
      setFormPanelVisible(false);
      setSaveLoading(false);
      companyForm.resetFields();
    }

    if (companyLoader.error && companyLoader.completed) {
      setSaveLoading(false);
    }
  }, [companyLoader]);

  useEffect(() => {
    if (userLoader.completed && userLoader.success) {
      setFormPanelVisible(false);
      setSaveLoading(false);
      userForm.resetFields();
    }
  }, [userLoader]);

  useEffect(() => {
    dispatch(
      actions.companiesLoadRequest(
        typeOfTrainings,
        true,
        currentPage,
        pageSize,
        textToFilter,
        sortingField,
        sortOrder === 'ascend' ? 'ASC' : 'DESC',
      ),
    );
    if (companyLoader.completed && companyLoader.success) {
      setFormPanelVisible(false);
      setSaveLoading(false);
      companyForm.resetFields();
    }

    if (companyLoader.error && companyLoader.completed) {
      setSaveLoading(false);
    }
  }, [
    currentPage,
    pageSize,
    textToFilter,
    sortOrder,
    sortingField,
    companyLoader,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [textToFilter, sortingField, sortOrder]);

  const debouncedSetTextFilter = React.useMemo(
    () => debounce((text: string) => setTextToFilter(text), 1500),
    [],
  );

  React.useEffect(() => {
    return () => {
      debouncedSetTextFilter.cancel();
    };
  }, [debouncedSetTextFilter]);

  const [assessmentForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [userForm] = Form.useForm();

  const handleSaveButtonPress = () => {
    setSaveLoading(true);
    switch (selectedEntity) {
      case Entity.COMPANY:
        return companyForm.submit();
      case Entity.ASSESSMENT:
        return assessmentForm.submit();
      case Entity.USER:
        return userForm.submit();
    }
  };

  const showSetupAssessmentPanel = (companyId: number): void => {
    setSelectedEntity(Entity.ASSESSMENT);
    setSelectedCompanyId(companyId);
    setFormPanelTitle('Setup new assessment');
    setFormPanelVisible(true);
  };

  const showNewUserPanel = (companyId: number): void => {
    setSelectedEntity(Entity.USER);
    setSelectedCompanyId(companyId);
    setFormPanelTitle('Add new user');
    setFormPanelVisible(true);
  };

  const showEditCompanyPanel = ({
    id,
    name,
    state,
    address,
    typeOfTraining,
    employees,
    licenses,
    companyType,
    trainingType,
  }: CompanyState): void => {
    setSelectedEntity(Entity.COMPANY);
    setSelectedCompanyId(id);
    companyForm.setFieldsValue({
      name,
      state,
      address,
      typeOfTraining,
      employees,
      licenses,
      companyType,
      trainingTypeId: trainingType?.id,
    });
    setFormPanelTitle('Edit company profile');
    setFormPanelVisible(true);
  };

  const showCreateCompanyPanel = (): void => {
    setSelectedEntity(Entity.COMPANY);
    setSelectedCompanyId(0);
    setFormPanelTitle('Create new company');
    setFormPanelVisible(true);
  };

  const handleSortingChange = (
    _: any,
    __: any,
    sorter: { order: 'ascend' | 'descend'; field: string },
  ) => {
    sorter.order === undefined
      ? setSortOrder('ascend')
      : setSortOrder(sorter.order);
    sorter.order === undefined
      ? setSortingField('name')
      : setSortingField(sorter.field);
  };

  return (
    <>
      <TopNavbar
        title="Companies/Assessments"
        prevRoute={null}
        extraOptions={[
          <Button
            onClick={showCreateCompanyPanel}
            title="Create new company"
            key={1}
          />,
        ]}
      />
      <PageContentContainer>
        <SearchContainer>
          <SearchInput
            onChange={(e) => debouncedSetTextFilter(e.target.value)}
            placeholder="Company name"
          />
        </SearchContainer>
        <CompanyListContainer>
          <CompanyListingTable
            companies={companies}
            showSetupAssessmentPanel={showSetupAssessmentPanel}
            showEditCompanyPanel={showEditCompanyPanel}
            showNewUserPanel={showNewUserPanel}
            loading={companiesLoader.loading}
            handleSortingChange={handleSortingChange}
            sortOrder={sortOrder}
            sortingField={sortingField}
          />
        </CompanyListContainer>
        <SlidingPanel
          title={formPanelTitle}
          visible={formPanelVisible}
          saveLabel="Save"
          loading={saveLoading}
          onClose={() => {
            setFormPanelVisible(false);
            assessmentForm.resetFields();
            companyForm.resetFields();
          }}
          onSave={handleSaveButtonPress}
        >
          {selectedEntity === Entity.COMPANY && (
            <FormCreateCompany
              companyForm={companyForm}
              companyId={selectedCompanyId}
              trainingTypes={trainingTypes}
            />
          )}
          {selectedEntity === Entity.ASSESSMENT && (
            <FormSetupAssessment
              assessmentForm={assessmentForm}
              assessmentTypes={assessmentTypes}
              companyId={selectedCompanyId}
            />
          )}
          {selectedEntity === Entity.USER && (
            <FormCreateUser
              userForm={userForm}
              companyId={selectedCompanyId}
              excludeRoles={COMPANY_GLOBAL_ROLES}
              trainingTypes={trainingTypes}
              userRole={userRole}
              setUserRole={setUserRole}
            />
          )}
        </SlidingPanel>
        {paginatedCompaniesData.totalItems > 20 && (
          <Pagination
            current={currentPage}
            items={paginatedCompaniesData.totalItems}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={(size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
            sizes={[20, 50, 75]}
            isNewStyle={true}
          />
        )}
      </PageContentContainer>
    </>
  );
};
